import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import LogoImage from '../assets/logo.png';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  colorPrimary: {
    backgroundColor: "#00529C"
  },
  logo: {
    maxHeight: 21,
    width: "auto"
  },
  regular: {
    "@media (min-width: 600px)": {
      minHeight: 56,
    }
  },
}));

const AppBarLocal = ({ backTo, className }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <AppBar position="static" classes={{ colorPrimary: classes.colorPrimary }}>
        <Toolbar classes={{ regular: classes.regular }}>
          {backTo && (
            <IconButton component={Link} to={backTo} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <ArrowBackIcon />
            </IconButton>
          )}
          <img src={LogoImage} className={classes.logo} alt="Logo" />
        </Toolbar>
      </AppBar>
    </div>
  )
};

export default AppBarLocal;