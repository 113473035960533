import { useState } from 'react';
import Routes from './routes';
import { makeStyles } from '@material-ui/core/styles';
import { FormProvider } from './contexts/forms';

const useStyles = makeStyles((theme) => ({
  bumn: {
    width: "60%",
    height: "auto",
    position: "absolute",
    bottom: 20,
    right: 12,
    opacity: 0.3,
    zIndex: -1,
  },
}));

function App() {
  const [formData, setFormData] = useState();
  const classes = useStyles();
  return (
    <div className="App">
      <FormProvider value={[formData, setFormData]}>
        <Routes />
      </FormProvider>
    </div>
  );
}

export default App;
