import React, { useState } from "react";
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import ButtonLocal from '../components/button';
import AppBarLocal from '../components/appBar';

const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  } else if(
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else { 
    return false;
  }
}

const isiOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  center: {
    padding: 20,
    paddingTop: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  pharagraph: {
    margin: "2px 0",
    color: "#767676"
  },
  button: {
    marginTop: 20,
  }
}));

const Home = ({ location }) => {
  const [isUsingChrome] = useState(true);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarLocal />
      <div className={classes.center}>
        {isUsingChrome && (
          <>
            <p className={classes.pharagraph}>Selamat datang,</p>
            <p className={classes.pharagraph}>Untuk memulai silahkan isi data anda.</p>
            <ButtonLocal
              component={Link}
              startIcon={<CreateIcon />}
              variant="contained"
              color="primary"
              to={location.pathname === "/admin" ? "/form/gallery" : "/form"}
              className={classes.button}>
              Mulai Isi Data
            </ButtonLocal>
          </>
        )}
        {!isUsingChrome && (
          <>
            <p className={classes.pharagraph}>Silahkan gunakan browser Google Chrome</p>
          </>
        )}
      </div>
    </div>
  )
};

export default withRouter(Home);