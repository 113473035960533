import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: 10,
  },
  root: {
    width: "100%"
  }
}));

const TextFieldLocal = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.textField}>
      <TextField
        fullWidth
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.root
        }}
        variant="outlined"
        {...props}
      />
    </div>
  )
};

export default TextFieldLocal;