import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  containedPrimary: {
    backgroundColor: "#00529C",
    "&:hover": {
      backgroundColor: "#03427b"
    }
  },
}));

const ButtonLocal = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button classes={{ containedPrimary: classes.containedPrimary }} disableElevation {...props}>
      {children}
    </Button>
  )
};

export default ButtonLocal;