import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './pages/home';
import Form from './pages/form';
import Camera from './pages/camera';
import Confirmation from './pages/confirmation';

const Routes = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route path="/confirmation">
        <Confirmation />
      </Route>
      <Route path="/camera" exact>
        <Camera />
      </Route>
      <Route path="/camera/:status">
        <Camera />
      </Route>
      <Route path="/form" exact>
        <Form />
      </Route>
      <Route path="/form/:status">
        <Form />
      </Route>
      <Route path="/admin">
        <Home />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
);

export default Routes;