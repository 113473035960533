import React, { useState, useContext, useEffect  } from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AppBarLocal from '../components/appBar';
import constants from '../constants';
import TextFieldLocal from '../components/textField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonLocal from '../components/button';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MUIAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import FormContext from '../contexts/forms'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import pose from '../assets/pose.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  posePhotoWrapper: {
    textAlign: "center"
  },
  posePhoto: {
    width: "70%",
    height: "auto",
    marginTop: 20
  },
  paperFullWidth: {
    width: "100%",
    maxWidth: "100%",
    margin: 0,
    maxHeight: "100%",
    borderRadius: 0,
  }
}));

const isDev = process.env.NODE_ENV === 'development';

const getBranches = async () => {
  try {
    const response = await axios(constants.BRANCH_CHECK_URL);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const Form = ({ history, match }) => {
  const [formData, setFormData] = useContext(FormContext);
  const [branches, setBranches] = useState(["JAKARTA KELAPA GADING"]);

  const classes = useStyles();
  const [name, setName] = useState(isDev ? "Rudi Wahyudi" : "");
  const [nameError, setNameError] = useState("");

  const [name1, setName1] = useState(isDev ? "Rudi" : "");
  const [name1Error, setName1Error] = useState("");

  const [name2, setName2] = useState(isDev ? "Wahyudi" : "");
  const [name2Error, setName2Error] = useState("");

  const [pn, setPn] = useState(isDev ? "123456" : "");
  const [pnError, setPnError] = useState("");

  const [norek, setNorek] = useState(isDev ? "123456" : "");
  const [norekError, setNorekError] = useState("");

  const [unitKerja, setUnitKerja] = useState(isDev ? "JAKARTA KELAPA GADING" : "");
  const [unitKerjaError, setUnitKerjaError] = useState("");
  const [isValidating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const onDialogOpen = () => {
    setOpenDialog(true);
  }
  const onDialogClose = () => {
    setOpenDialog(false);
  }

  useEffect(() => {
    getBranches()
      .then((branchesResponse) => setBranches(branchesResponse))
  }, []);

  const validate = async () => {
    try {
      setValidating(true);
      const nameEmpty = name.length === 0;
      const name1Empty = name1.length === 0;
      const pnEmpty = pn.length === 0;
      const norekEmpty = norek.length === 0;
      const unitKerjaEmpty = unitKerja.length === 0;
      if (nameEmpty) {
        setNameError("Nama tidak boleh kosong.");
      }
      if (name1Empty) {
        setName1Error("Nama kartu baris atas tidak boleh kosong.");
      }
      if (pnEmpty) {
        setPnError("PN tidak boleh kosong.");
      }
      if (norekEmpty) {
        setNorekError("No rekening tidak boleh kosong.");
      }
      if (unitKerjaEmpty) {
        setUnitKerjaError("Unit kerja tidak boleh kosong.");
      }
  
      if (nameEmpty || name1Empty || pnEmpty || norekEmpty || unitKerjaEmpty || name1Error !== "" || name2Error !== "") {
        setError("Lengkapi form sebelum melanjutkan.");
        throw new Error("VALIDATION_FAILED");
      }
  
      // Check if data exist
      const response = await axios(`${constants.API_CHECK_URL}?no_personal=${pn}&norek=${norek}`);
      if (response.data !== "ok") {
        setError(response.data);
        throw new Error("DATA_EXIST");
      }
    } catch (error) {
      throw error;
    } finally {
      setValidating(false);
    }
  }

  const onValidated = () => {
    setFormData({
      name,
      name1,
      name2,
      pn,
      norek,
      unitKerja
    });
    history.push(match.params.status === 'gallery' ? "/camera/gallery" : "/camera");
  }

  const onButtonClicked = async () => {
    try {
      onDialogClose();
      await validate();
      onDialogOpen();
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    setError(null);
  }

  const capitalize = (value) => {
    return value && value.toUpperCase();
  }

  return (
    <>
      <AppBarLocal backTo="/" />
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Isi data
        </Typography>
        <TextFieldLocal
          error={nameError !== ""}
          helperText={nameError || "Pastikan yang anda input sesuai nama yang tercatat pada BRISTARS"}
          label="Nama Lengkap"
          placeholder="Masukkan nama anda"
          value={name}
          onChange={(event) => {
            setNameError("");
            setName(capitalize(event.target.value))
          }}
        />
        <TextFieldLocal
          error={name1Error !== ""}
          helperText={name1Error}
          label="Nama kartu baris atas"
          placeholder="Nama kartu baris atas"
          value={name1}
          onChange={(event) => {
            if (event.target.value.length > 12) {
              setName1Error("Maksimal 12 karakter");
            } else {
              setName1Error("");
            }
            setName1(capitalize(event.target.value))
          }}
        />
        <TextFieldLocal
          error={name2Error !== ""}
          helperText={name2Error}
          label="Nama kartu baris bawah (Optional)"
          placeholder="Nama kartu baris bawah"
          value={name2}
          onChange={(event) => {
            if (event.target.value.length > 12) {
              setName2Error("Maksimal 12 karakter");
            } else {
              setName2Error("");
            }
            setName2(capitalize(event.target.value))
          }}
        />
        <TextFieldLocal
          error={pnError !== ""}
          helperText={pnError}
          label="Personal Number (PN)"
          placeholder="Masukkan PN"
          value={pn}
          onChange={(event) => {
            setPnError("");
            setPn(capitalize(event.target.value))
          }}
        />
        <TextFieldLocal
          error={norekError !== ""}
          helperText={norekError}
          label="Nomor Rekening Payroll"
          placeholder="Masukkan Nomor Rekening Payroll"
          value={norek}
          onChange={(event) => {
            setNorekError("");
            setNorek(capitalize(event.target.value))
          }}
        />
        <Autocomplete
          fullWidth
          options={branches}
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setUnitKerjaError("");
            setUnitKerja(capitalize(value));
          }}
          value={unitKerja}
          renderInput={(params) => (
            <TextFieldLocal
              {...params}
              error={unitKerjaError !== ""}
              helperText={unitKerjaError}
              label="Unit Kerja"
              placeholder="Contoh: BRI Sukabumi"
            />
          )}
        />
        <ButtonLocal
          startIcon={!isValidating && <AddAPhotoIcon />}
          variant="contained"
          color="primary"
          disableElevation
          onClick={onButtonClicked}
          fullWidth>
          {isValidating ? "Memvalidasi..." : "Simpan dan ambil foto"}
        </ButtonLocal>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleClose}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MUIAlert onClose={handleClose} severity="error">
          {error}
        </MUIAlert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="false"
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Anda akan diarahkan untuk pemotretan, pastikan memenuhi ketentuan foto sebagai berikut:
          <ol>
            <li>
            Foto setengah badan, tersenyum dengan atau tanpa memperlihatkan gigi, posisi kepala menghadap ke depan dan posisi badan miring sekitar 45 derajat ke kanan (dari sisi pekerja).
            </li>
            <li>
            Bagi jabatan Senior Manager ke atas, Pria menggunakan jas warna gelap, kemeja putih dan berdasi serasi. Wanita menggunakan Blazer warna gelap, kemeja putih, apabila berhijab, hijab berwarna serasi selain putih.
            </li>
            <li>
            Bagi jabatan lainnya, Pria menggunakan kemeja panjang warna putih dan dasi berwarna serasi. Wanita menggunakan blouse/kemeja panjang berwarna putih, apabila berhijab, hijab berwarna serasi selain putih.
            </li>
            <li>
            Untuk hasil yang optimal, agar menggunakan latar polos dan kontras dengan pakaian, serta difotokan oleh orang lain menggunakan kamera dengan pixel yang paling optimal.
            </li>
          </ol>
          </DialogContentText>
          <div className={classes.posePhotoWrapper}>
            <img className={classes.posePhoto} src={pose} alt="" />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonLocal onClick={onDialogClose} color="primary">
            Batal
          </ButtonLocal>
          <ButtonLocal onClick={onValidated} color="primary" autoFocus>
            Lanjutkan
          </ButtonLocal>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default withRouter(Form);