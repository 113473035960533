import React from "react";
import ArrowBackIosRounded from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ButtonLocal from '../components/button';
import AppBarLocal from '../components/appBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  center: {
    padding: 20,
    paddingTop: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  pharagraph: {
    margin: "2px 0",
    fontSize: 17,
    fontWeight: "500",
    color: "#767676",
    "& a": {
      color: "#00529C"
    }
  },
  button: {
    marginTop: 20,
  }
}));

const Confirmation = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBarLocal />
      <div className={classes.center}>
        <p className={classes.pharagraph}>Data telah disimpan. Untuk kritik dan saran dapat dikirimkan ke email <a href="mailto:ktpp@koperasiswakaryabri.co.id">ktpp@koperasiswakaryabri.co.id</a>. Terimakasih.</p>
        <ButtonLocal
          component={Link}
          startIcon={<ArrowBackIosRounded />}
          variant="contained"
          color="primary"
          to="/"
          className={classes.button}>
          Kembali ke Beranda
        </ButtonLocal>
      </div>
    </div>
  )
};

export default Confirmation;