import React, { useRef, useState, useCallback, useContext } from "react";
import {Camera} from "react-camera-pro";
import { post } from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import AppBarLocal from '../components/appBar';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MUIAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CameraIcon from '@material-ui/icons/CameraAlt';
import XIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import CollectionsIcon from '@material-ui/icons/Collections';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import SendIcon from '@material-ui/icons/Send';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import guideImage from '../assets/guide.png';
import constants from '../constants';
import FormContext from '../contexts/forms'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import pose from '../assets/pose.jpg';

const isDev = process.env.NODE_ENV === 'development';

const flipOrientation = (srcBase64) => {
  return new Promise((resolve) => {
    const img = new Image();	
  
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");
      
      canvas.width = width;
      canvas.height = height;
  
      ctx.transform(-1, 0, 0, 1, width, 0);
  
      // draw image
      ctx.drawImage(img, 0, 0);
  
      // export base64
      resolve(canvas.toDataURL());
    };
  
    img.src = srcBase64;
  })
}


const useStyles = makeStyles((theme) => ({
  appBarCamera: {
    height: 56,
  },
  actions: {
    position: "relative",
    backgroundColor: "#00529C",
    height: 80,
  },
  captureIcon: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftIcon: {
    position: "absolute",
    left: 0,
    right: 140,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightIcon: {
    position: "absolute",
    left: 140,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    position: "relative",
    zIndex: 100,
    backgroundColor: "#fff",
    padding: 18,
    "&:hover": {
      backgroundColor: "#fff",
    }
  },
  iconSmall: {
    padding: 14,
  },
  icon: {
    width: "1.2em",
    height: "1.2em",
    fill: "#00529C"
  },
  iconIconSmall: {
    width: "1em",
    height: "1em",
  },
  camera: {
    zIndex: 1,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#000",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  cameraWrapper: {
    height: "100%",
    width: "calc((100vh - (56px + 80px)) * 650/1004)",
    margin: "0 auto",
    position: "relative",
    "@media (max-aspect-ratio: 434/813)": {
      width: "100%",
      height: "auto",
      paddingBottom: "154%",
    }
  },
  cameraInner: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  image: {
    zIndex: 2,
  },
  imageInverted: {
    "-webkit-transform": "scaleX(-1)",
    transform: "scaleX(-1)",
  },
  disabledIcon: {
    backgroundColor: "#000 !important",
    "& svg": {
      fill: "#4b4b4b",
    }
  },
  cameraMain: {
    "& > *": {
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
    }
  },
  guide: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 3,
  },
  outer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  snackbar: {
    bottom: 100,
  },
  iconCamera: {
    fill: "#fff"
  },
  cameraProWrapper: {
    display: "flex",
    alignItems: "self-start",
    justifyContent: "flex-end",
    padding: 10,
    zIndex: 3,
  },
  inputFileWrapper: {
    position: "relative",
  },
  inputFile: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 48,
    height: 48,
    overflow: "hidden",
    opacity: 0,
  },
  imagePreviewWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000",
    zIndex: 100,
  },
  posePhotoGrid: {
    width: "50% !important",
    padding:" 0 5px",
    "& p": {
      color: "#fff",
      display: "flex",
      alignItems: "flex-end",
    }
  },
  guideFlipped: {
    transform: "scaleX(-1)",
  },
}));

const CameraPage = ({ history, match }) => {
  const [formData] = useContext(FormContext)
  const classes = useStyles();
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [orientation, setOrientation] = useState(isDev ? "user" : "environment");
  const screen = useFullScreenHandle();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleClose = () => {
    setError(null);
  }
  const handleCloseInfo = () => {
    setInfo(null);
  }

  const changeOrientation = () => {
    const orient = camera.current.switchCamera();
    setOrientation(orient);
  }

  const onSubmitPhotoFinal = async () => {
    try {
      handleDialogClose();
      await onSubmit(constants.API_UPLOAD_URL, "Sedang submit data...");
      history.push("/confirmation");
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  }

  const onSubmitPhoto = async () => {
    try {
      const result = await onSubmit(constants.API_PREVIEW_URL, "Sedang memuat preview...");
      setResult(result);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  }

  const onSubmit = useCallback(async (url, loadingText) => {
    try {
      if (Object.keys(formData || {}).length === 6) {
        const { name, name1, name2, norek, pn, unitKerja } = formData;
  
        const myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');

        const myInit = {
          method: 'GET',
          headers: myHeaders,
        };

        let imageProcesses = image;
        if (orientation === "user") {
          imageProcesses = await flipOrientation(image);
        }

        const res = await fetch(imageProcesses, myInit);
        const blob = await res.blob();
  
        const fd = new FormData();
        const file = new File([blob], `photo-${new Date().getTime()}.jpeg`);
        fd.append('file', file);
        fd.append('no_personal', pn);
        fd.append('nama', name);
        fd.append('name_1', name1);
        fd.append('name_2', name2);
        fd.append('unit_kerja', unitKerja);
        fd.append('norek', norek);

        setLoading(true);
        setInfo(loadingText);
        const result = await post(url, fd);
        setLoading(false);
        return result.data;
      } else {
        console.error("Data empty!");
        history.push("/form");
      }
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      handleCloseInfo();
    }
  }, [image])

  const getImageUrl = () => {
    const imgs = document.querySelectorAll("#target_file > img");
    if (imgs.length) {
      const URL = imgs[0].getAttribute("src");
      return URL;
    } else {
      return null;
    }
  }

  const onTakingPhoto = async () => {
    const photo = camera.current.takePhoto();
    if (orientation === "user") {
      const photoFlipped = await flipOrientation(photo);
      setImage(photoFlipped);
    } else {
      setImage(photo);
    }
  }

  const onChangePicture = e => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <FullScreen handle={screen}>
      <div className={classes.outer}>
        {!screen.active && (
          <AppBarLocal backTo="/form" className={classes.appBarCamera} />
        )}
        <div className={classes.camera}>
          <div className={classes.cameraWrapper}>
            <div className={classes.cameraInner}>
              {!result && (
                <div className={classNames(classes.guide, {[classes.guideFlipped]: orientation === 'user'})}>
                  <img src={guideImage} alt="Guide" />
                </div>
              )} 
              <div className={classes.cameraMain}>
                {!result && (
                  <Camera ref={camera} facingMode={orientation} />
                )}
                <div className={classes.cameraProWrapper}>
                  {(!image && !result && match.params.status === 'gallery') && (
                      <IconButton size="medium" className={classes.inputFileWrapper}>
                        <input id="profilePic" type="file" onChange={onChangePicture} className={classes.inputFile} />
                        <CollectionsIcon className={classNames(classes.icon, classes.iconIconSmall, classes.iconCamera)} />
                      </IconButton>
                  )}
                  <IconButton size="medium" className={classes.ds} onClick={changeOrientation}>
                    <FlipCameraIosIcon className={classNames(classes.icon, classes.iconIconSmall, classes.iconCamera)} />
                  </IconButton>
                </div>
                {image && (
                  <img src={image} alt="Preview" className={classNames(classes.image)} />
                )}
                {result && (
                  <div className={classNames(classes.imagePreviewWrapper)}>
                    <div className={classes.posePhotoGrid}>
                      <p>
                        Contoh Foto:
                      </p>
                      <img className={classes.posePhoto} src={pose} alt="" />
                    </div>
                    <div className={classes.posePhotoGrid}>
                      <p>
                        Hasil foto:
                      </p>
                      <div id="target_file" dangerouslySetInnerHTML={{ __html: result }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.leftIcon}>
            {screen.active && (
              <IconButton size="medium" className={classNames(classes.iconButton, classes.iconSmall)} onClick={screen.exit}>
                <FullscreenExitIcon className={classNames(classes.icon, classes.iconIconSmall)} />
              </IconButton>
            )}
            {!screen.active && (
              <IconButton size="medium" className={classNames(classes.iconButton, classes.iconSmall)} onClick={screen.enter}>
                <FullscreenIcon className={classNames(classes.icon, classes.iconIconSmall)} />
              </IconButton>
            )}
          </div>
          <div className={classes.captureIcon}>
            {(image || result) && (
              <IconButton size="medium" className={classes.iconButton} onClick={() => {
                setImage(null);
                setResult(null);
              }}>
                <XIcon className={classes.icon} />
              </IconButton>
            )}
            {!(image || result) && (
              <IconButton size="medium" className={classes.iconButton} onClick={onTakingPhoto}>
                <CameraIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
          <div className={classes.rightIcon}>
            {!result && (
              <IconButton onClick={onSubmitPhoto} size="medium" className={classNames(classes.iconButton, classes.iconSmall)} disabled={!image} classes={{ disabled: classes.disabledIcon }}>
                {!loading && (
                  <CheckIcon className={classNames(classes.icon, classes.iconIconSmall)} />
                )}
                {loading && (
                  <CircularProgress size={20} />
                )}
              </IconButton>
            )}
            {result && (
              <IconButton onClick={handleDialogClickOpen} size="medium" className={classNames(classes.iconButton, classes.iconSmall)}>
                {!loading && (
                  <SendIcon className={classNames(classes.icon, classes.iconIconSmall)} />
                )}
                {loading && (
                  <CircularProgress size={20} />
                )}
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleClose}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MUIAlert onClose={handleClose} severity="error">
          {error}
        </MUIAlert>
      </Snackbar>
      <Snackbar
        open={!!info}
        autoHideDuration={6000}
        onClose={handleCloseInfo}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MUIAlert onClose={handleCloseInfo} severity="info">
          {info}
        </MUIAlert>
      </Snackbar>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Anda Yakin Unggah Foto?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Foto yang sudah diunggah tidak dapat diganti/dikoreksi/melakukan unggah ulang.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Batal
          </Button>
          <Button onClick={onSubmitPhotoFinal} color="primary" autoFocus>
            Unggah
          </Button>
        </DialogActions>
      </Dialog>
    </FullScreen>
  );
}

export default withRouter(CameraPage);